import autobind from "auto-bind"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openBookingNoticeDialog(options)
  }

  previewBookingNotice(options = {}) {
    const type = "BookingNoticeDialog"
    const { mode = "preview" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  openBookingNoticeDialog(options = {}) {
    const type = "BookingNoticeDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    if (this.entity.locked === '1') {
      const notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "This Booking Notice is locked",
        message: `Please unlock this Booking Notice to make any changes.`,
        visible: true,
        decay: 3500
      }
      store.dispatch("core/notifications/addToNotifications", notificationsPayload)
    } else {
      store.dispatch("core/dialogStack/addDialog", {type, mode, id})
    }
  }

  openViewBookingsDialog(options = {}) {
    const type = "ViewBookingsDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  openCloneBookingNoticeDialog() {
    const type = "CloneBookingNoticeDialog"
    const mode = 'create'
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  async createBookingNotice() {
    const { id, store } = this.entity
    const response = await this.entity.Api.createNoticeDocument(id)

    const result = response.response.data.data.data
    const fileLocation = result.fileLocation
    let notificationsPayload

    if (fileLocation) {
      notificationsPayload = {
        component: "Toast",
        type: "download",
        title: "Thank you",
        position: "top-right",
        message: `Your Booking Notice has been created. Downloaded here: <a href="${fileLocation}" target="_blank">Booking Notice #${id}</a>`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error creating Booking Notice",
        message: `Sorry, your Booking Notice could not be created.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)
  }


   get actionsList() {
    const self = this.entity

     let lockNotice

     if (self.locked !== '1') {
       lockNotice = {
         visible: true,
         label: "Lock Booking Notice",
         icon: "el-icon-lock",
         onClick: () => self.Api.lockBookingNotice(self.id),
       }
     } else {
        lockNotice = {
          visible: true,
          label: "Unlock Booking Notice",
          icon: "el-icon-unlock",
          onClick: () => self.Api.unlockBookingNotice(self.id),
        }
     }

    return [
      {
        visible: true,
        label: "Preview Booking Notice",
        icon: "el-icon-view",
        onClick: () => window.open(
            `https://bookings.essentialsm.com.au/#/BookingPages/booking/${self.id}`,
            '_blank' // <- This is what makes it open in a new window.
        )
      },
      {
        visible: true,
        divided: true,
        label: "Download Booking Notice",
        icon: "el-icon-printer",
        onClick: () => this.createBookingNotice()
      },
      {
        visible: true,
        label: "View all Bookings",
        icon: "el-icon-tickets",
        onClick: () => this.openViewBookingsDialog({ id: self.id })
      },
      {
        visible: true,
        label: "Edit Booking Notice",
        icon: "el-icon-edit",
        onClick: () => this.openBookingNoticeDialog({ mode: "edit" })
      },
      {
        visible: true,
        label: "Clone Booking Notice",
        icon: "el-icon-document-add",
        onClick: () => this.openCloneBookingNoticeDialog({ mode: "edit" })
      },
        lockNotice,
      {
        visible: true,
        divided: true,
        class: "danger",
        label: "Delete",
        icon: "el-icon-delete",
        onConfirm: () => self.Api.destroy(self),
        confirmProps: {
          title: "Are you sure you want to delete this Booking Notice?",
          confirmButtonText: "Delete Booking Notice"
        }
      }
    ].filter(({ visible }) => visible)
  }
}

export default Actions
