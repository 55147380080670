<template>
  <div
      class="status-column">
    <template v-if="scope.row.timeBlocks">
      <ul>
        <li
            v-for="(timeBlock, index) in formattedTimeBlocks"
            :key="`timeBlock-${index}`">
          <div class="time">
            <strong>{{ formatTime(timeBlock.startTime) }}</strong> to <strong>{{ formatTime(timeBlock.endTime) }}</strong>
          </div>
          <div class="slots">| <strong>{{ timeBlock.bookingSlots }}</strong> slot<template v-if="timeBlock.bookingSlots != 1">s</template></div>
        </li>
      </ul>
    </template>
  </div>  
</template>
<script>
import moment from 'moment';

export default {
  name: "BookingTimeBlocksColumn",
  filters: {
    spaceUnderscore(string) {
      return string.replace("_", " ")
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedTimeBlocks() {
      return this.scope.row.timeBlocks
    }
  },
  methods: {
    filterStatus(value, row) {
      return row.active === value
    },
    formatTime(timeInput) {
      /**
       * Formats time input to "hh:mm AM/PM" format
       * Handles two input formats:
       * 1. ISO date string: "2025-03-03T22:00:00.000Z"
       * 2. 24-hour time string: "14:00"
       *
       * @param {string} timeInput - Time in either ISO format or 24-hour format
       * @returns {string} Time formatted as "hh:mm AM/PM"
       */

      let hours, minutes;

      // Check if input is ISO format or simple time format
      if (timeInput.includes('T')) {
        // ISO format: "2025-03-03T22:00:00.000Z"
        const date = new Date(timeInput);
        hours = date.getHours();
        minutes = date.getMinutes();
      } else {
        // Simple time format: "14:00"
        const parts = timeInput.split(':');
        hours = parseInt(parts[0], 10);
        minutes = parseInt(parts[1], 10);
      }

      // Convert to 12-hour format
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert "0" to "12"

      // Add leading zeros if needed
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes} ${period}`;
    }
  }
}
</script>

<style lang="scss" scoped>

  .status-column {
    margin: 0 auto;
    .el-tag {
      min-width: 90%;
      margin: auto;
      text-align: center;
      box-shadow: var(--box-shadow-container-light);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      display: flex;

      div {
        &.time {
          margin-right: 10px;
        }
      }
    }
  }
</style>
