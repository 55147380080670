<template>
  <div class="id-column">
    <div>{{ scope.row.description }}</div>
    <div class="download-actions">
      <a :href="bookingNoticeDownloadUrl">
        Download Notice
      </a>
      <el-tooltip
          content="Copy download link"
          placement="top"
          :open-delay="300">
        <el-button
            class="copy-button"
            size="mini"
            type="text"
            @click="copyLinkToClipboard">
          <i :class="['copy-icon', copied ? 'el-icon-check' : 'el-icon-document-copy']" />
          {{ copied ? 'Copied!' : 'Copy link' }}
        </el-button>
      </el-tooltip>
    </div>
    <el-message
        v-if="showMessage"
        :type="messageType"
        :message="messageText"
        :duration="2000"
        @close="showMessage = false" />
  </div>
</template>

<script>
import config from "@/config";
export default {
  name: "BookingIdColumn",
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      copied: false,
      showMessage: false,
      messageType: 'success',
      messageText: ''
    };
  },
  computed: {
    bookingNoticeDownloadUrl() {
      let url = `${config.apiConfig.lightwerk.api}/notice-${this.scope.row.id}`;
      url = url.replace('bookingsapi', 'downloads');
      url = url.replace('/api', '');
      return url;
    }
  },
  methods: {
    copyLinkToClipboard() {
      // Create a temporary input element
      const tempInput = document.createElement('input');
      tempInput.value = this.bookingNoticeDownloadUrl;
      document.body.appendChild(tempInput);

      // Select and copy the URL
      tempInput.select();

      try {
        const successful = document.execCommand('copy');

        if (successful) {
          // Show success state
          this.copied = true;
          this.messageType = 'success';
          this.messageText = 'Link copied to clipboard!';
          this.showMessage = true;

          // Reset copied state after 2 seconds
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        } else {
          // Show error message if copying failed
          this.messageType = 'error';
          this.messageText = 'Failed to copy link. Please try again.';
          this.showMessage = true;
        }
      } catch (err) {
        // Show error message if exception occurred
        this.messageType = 'error';
        this.messageText = 'Failed to copy link. Please try again.';
        this.showMessage = true;
      }

      // Remove the temporary input element
      document.body.removeChild(tempInput);
    }
  }
};
</script>

<style lang="scss" scoped>
.id-column {
  margin: 0 auto;

  .el-tag {
    margin: auto;
    box-shadow: var(--box-shadow-container-light);
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lock-status {
    margin-left: 0.25rem;
  }

  .download-actions {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    a {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      i {
        margin-right: 0.25rem;
      }
    }

    .copy-button {
      display: flex;
      align-items: center;
      padding: 0;

      .copy-icon {
        margin-right: 0.25rem;
      }

      &:hover {
        color: #409EFF;
      }
    }
  }
}
</style>