import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config"

const baseURL = config?.apiConfig?.lightwerk?.api
const endpoint = "/bookingNotices"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint,
  endpoints: {
    getBookings: "/bookingNotices/getBookings/:id",
    exportBookings: "/bookingNotices/exportBookings/:id",
    exportBookingsBulk: "/bookingNotices/exportBookingsBulk/:id",
    deleteBooking: "/bookingNotices/deleteBooking/",
    resendBookingConfirmation: "/bookingNotices/resendBookingConfirmation/",
    createNoticeDocument: "/bookingNotices/createNoticeDocument/",
  }
})

const { endpoints } = defaults

const apiActions = {
  ...defaults.apiActions,
  getBookings(id = "", options = {}) {
    const url = endpoints.getBookings({ ...options, id })
    options.id = id
    return this.post(url, options)
  },
  exportBookings(id = "", options = {}) {
    const url = endpoints.exportBookings({ ...options, id })
    options.id = id
    return this.post(url, options)
  },
  exportBookingsBulk(id = "", options = {}) {
    const url = endpoints.exportBookingsBulk({ ...options, id })
    options.id = id
    return this.post(url, options)
  },
  deleteBooking(id = "") {
    return this.post(endpoints.deleteBooking(), { id: id })
  },
  resendBookingConfirmation(id = "") {
    return this.post(endpoints.resendBookingConfirmation(), { id: id })
  },
  createNoticeDocument(id = "") {
    return this.post(endpoints.createNoticeDocument(), { id: id })
  },
}

const routes = server => {
  defaults.routes(server)
}

export { apiActions }
export default routes
