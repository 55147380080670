const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Booking Notices",
    options: [
      {
        label: "Notice Active Status",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.noticeStatus",
        inputType: "select",
        props: {
          options: [
            {
              value: "active",
              label: "Active only (not expired)"
            },
            {
              value: "inactive",
              label: "Inactive only"
            },
            {
              value: "expired",
              label: "expired only"
            }
          ]
        }
      },
      {
        label: "Notice Date (Range)",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.date",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Creation Date"
        }
      },
      {
        label: "Site",
        queryType: "search",
        urlKey: "filter.site",
        inputType: "entity-select",
        props: {
          entity: "Site"
        }
      },
      {
        label: "Notice Type",
        queryType: "search",
        urlKey: "filter.noticeType",
        inputType: "select",
        props: {
          options: [
            {
              value: "fire_safety_repairs",
              label: "Fire Safety Repairs"
            },
            {
              value: "routine_fire_inspection",
              label: "Routine Fire Inspection"
            }
          ]
        }
      },
    ]
  },
]