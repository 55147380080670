<template>
  <fragment>
    <CoreScreen
        card
        class="noticeTemplateBuilder-wrapper"
        header="Booking Notices"
        icon="NoticeTemplateBuilderIcon"
        :create-button="{ label: 'Create Booking Notice', onCreate: createTemplate }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { BookingNotice } from "@/Modules/Models"
export default {
  name: "BookingNotices",
  methods: {
    createTemplate() {
      new BookingNotice().Actions.openBookingNoticeDialog()
    }
  }
}
</script>
