<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps">
    <template slot="content">
      <BookingsOverview :id="id" />
    </template>
  </CoreDialog>
</template>

<script>
import BookingsOverview from "../views/BookingsOverview"
import { BookingNotice } from "@/Modules/Models"

export default {
  name: "BookingNoticeDialog",
  components: {
    BookingsOverview
  },
  props: {
    id: { type: [String, Number], default: null },
    closeDialog: { type: Function, required: true },
    dialogProps: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    bookingNoticeData() {
      return BookingNotice.find(this.id)
    },
    mode() {
      return this.dialogProps.mode
    },
    title() {
      if (this.bookingNoticeData) {
        return `Bookings Overview for (ID: ${this.id})`
      }

      return "Create New Booking Notice"
    }
  },
  mounted() {
    if (this.id) {
      this.fetchBookingNotice()
    } else {
      this.isLoading = false
    }
  },
  methods: {
    async fetchBookingNotice() {
      this.isLoading = true

      await BookingNotice.api().fetchById(this.id)

      this.isLoading = false
    }
  }
}
</script>