import routes, { apiActions } from "./routes"

// id title description type timeBlocks siteId date timeStart timeEnd userId createdAt updatedAt active visibility

const attributes = {
    id: 1,
    active: 1,
    title: null,
    description: null,
    selection: null,
    noticeType: null,
    timeBlocks: null,
    createdAt: null,
    updatedAt: null,
    siteId: null,
    date: null,
    startTime: null,
    endTime: null,
    bookingInactiveDate: null,
    locked: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition