<template>
  <div class="status-column">
    <template v-if="scope.row.siteId">
      {{ siteName }}
    </template>
  </div>  
</template>
<script>
import { Site } from "@/Modules/Models"
export default {
  name: "SiteColumn",
  filters: {
    spaceUnderscore(string) {
      return string.replace("_", " ")
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      siteName: null
    }
  },

  async mounted() {
    await Site.api().fetchById(this.scope.row.siteId)
    this.siteName = Site.find(this.scope.row.siteId).title

  },
  methods: {
    filterStatus(value, row) {
      return row.active === value
    }
  }
}
</script>

<style lang="scss" scoped>

  .status-column {
    margin: 0 auto;
    .el-tag {
      min-width: 90%;
      margin: auto;
      text-align: center;
      box-shadow: var(--box-shadow-container-light);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
</style>
