import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import BulkActions from "./bulkActions"
import config from "@/config"

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/products"

class CaneToadProduct extends ExtendedModel {
  static entity = "CaneToadProduct"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async bulkActivate(productIds) {
        const url = `${baseURL}${endpoint}/bulkActivate/${productIds}`
        return this.get(url, null)
      },
      async bulkDeactivate(productIds) {
        const url = `${baseURL}${endpoint}/bulkDeactivate/${productIds}`
        return this.get(url, null)
      },
      async importNetoProducts(skus) {

        // skus is an object, turn it into an array
        const skuArray = Object.keys(skus).map(key => skus[key])
        const skuString = skuArray.join(',')

        const url = `${baseURL}${endpoint}/importNetoProductSKUs/${skuString}`
        return this.get(url, null)
      }
    },
  }

  get Actions() {
    return new Actions(this)
  }

  get BulkActions() {
    return new BulkActions(this)
  }

  get timeBlock() {
    return `${this.timeFrame} min`
  }

  get SellPrice() {
    if (this.DefaultPrice > 0) {
      return `${parseFloat(this.DefaultPrice).toFixed(2)} AUD`
    }
    return '0.00 AUD'
  }

  get PreOrderPrice() {
    if (this.PreOrderDepositPrice > 0) {
      return `${parseFloat(this.PreOrderDepositPrice).toFixed(2)} AUD`
    }
    return 'N/A'
  }

  get valueList() {
    const {id} = this
    return {label: `${this.Name}`, value: id, id, extra: this.SKU}
  }

  get productThumbnail() {
    if (this.ImageList && this.ImageList.length > 0) {
      return `https://api.canetoadequip.com.au/pic/304x304/media/productImages/${this.ImageList[0]}`
    }
  }

}
export default CaneToadProduct
