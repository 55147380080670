<template>
  <div class="id-column">
    {{ scope.row.id }}
    <el-tag
        v-if="scope.row.locked === '1'"
        type="danger"
        class="el-icon-lock lock-status" />
  </div>
</template>
<script>
export default {
  name: "BookingIdColumn",
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
.id-column {
  margin: 0 auto;
  .el-tag {
    margin: auto;
    box-shadow: var(--box-shadow-container-light);
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lock-status {
    margin-left: 0.25rem;
  }
}
</style>
