import BookingNoticeDialog from "./BookingNoticeDialog"
import ViewBookingsDialog from "./ViewBookingsDialog"
import CloneBookingNoticeDialog from "./CloneBookingNoticeDialog"

export default [
  {
    name: "BookingNoticeDialog",
    Component: BookingNoticeDialog,
    permissions: "BookingNotice:BookingNotice:canRead"
  },
  {
    name: "ViewBookingsDialog",
    Component: ViewBookingsDialog,
    permissions: "BookingNotice:BookingNotice:canRead"
  },
  {
    name: "CloneBookingNoticeDialog",
    Component: CloneBookingNoticeDialog,
    permissions: "BookingNotice:BookingNotice:canRead"
  }
]