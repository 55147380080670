import BookingNoticeStatusColumn from "./BookingNoticeStatusColumn";
import BookingTimeBlocksColumn from "./BookingTimeBlocksColumn";
import BookingIdColumn from "./BookingIdColumn";
import BookingDescriptionColumn from "./BookingDescriptionColumn";
import SiteColumn from "./SiteColumn";

const defaultColumns = [
  {
    prop: "id",
    label: "ID",
    Component: BookingIdColumn
  },
  {
    prop: "Site",
    label: "Site",
    Component: SiteColumn
  },
  {
    prop: "description",
    label: "Description",
    Component: BookingDescriptionColumn
  },
  {
    prop: "NoticeType",
    label: "Notice Type",
  },
  {
    prop: "NoticeDate",
    label: "Notice Date",
  },
  {
    prop: "ExpiryDate",
    label: "Expiry Date",
  },
  {
    prop: "timeBlocks",
    label: "Time Blocks",
    Component: BookingTimeBlocksColumn
  },
  {
    prop: "active",
    label: "Status",
    Component: BookingNoticeStatusColumn
  }
]

export default defaultColumns