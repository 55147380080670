<template>
  <div class="status-column">
    <el-tag
        v-if="scope.row.active"
        :type="status !== 'active' ? 'danger' : 'success'"
        disable-transitions>
      {{ status }}
    </el-tag>
  </div>  
</template>
<script>
export default {
  name: "NoticeTemplateBuilderStatusColumn",
  filters: {
    spaceUnderscore(string) {
      return string.replace("_", " ")
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  computed: {
    status() {
      // bookingInactiveDate example "2025-03-07"
      if (this.scope.row.bookingInactiveDate <= new Date().toISOString().split('T')[0]) {
        return "expired"
      } else if (this.scope.row.active === '0'){
        return "inactive"
      } else {
        return "active"
      }

    }
  },
  methods: {
    filterStatus(value, row) {
      return row.active === value
    }
  }
}
</script>

<style lang="scss" scoped>
  .status-column {
    margin: 0 auto;
    .el-tag {
      margin: auto;
      text-align: center;
      box-shadow: var(--box-shadow-container-light);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
</style>
