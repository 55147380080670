import createApp from "./Modules/Core"
// import AppBase from "@/Modules/OtocareBase";
// import AppBase from "@/Modules/CaneToadEquipAdminBase";
// import AppBase from "@/Modules/FMSClientBase";
import AppBase from "@/Modules/FMSBase";
// UI Framework Element UI
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import locale from "element-ui/lib/locale/lang/en"
import VueFilterDateFormat from "vue-filter-date-format"
import Sortable2 from "vue2-sortable"

export default createApp({
  Base: AppBase,
  initialState: {useMockData: false},
  addLibraries: Vue => {
    Vue.use(ElementUI, {locale})
    Vue.use(VueFilterDateFormat)
    Vue.use(Sortable2)
  }
})
