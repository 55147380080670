<template>
  <div>
    <core-data-table-v2
        :model="bookingNoticesModel"
        :columns="columns"
        :searches-and-filters="filters"
        table-name="booking-notices-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { BookingNotice } from "@/Modules/Models"
import columns from "../components/BookingNoticeTableView/columns"
import filterConfig from "../components/BookingNoticeTableView/filterConfig"
export default {
  name: "BookingNoticesTableView",
  computed: {
    filters() {
      return filterConfig
    },
    bookingNoticesModel() {
      return BookingNotice
    },
    columns() {
      return columns
    }
  }
}
</script>
