<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps">
    <template slot="content">
      <ElAlert
          show-icon
          type="warning"
          title="Cloning a Booking Notice will create a new Booking Notice with the same details as the original." />
      <BookingNoticeForm
          :dialog-props="dialogProps"
          :form-data="bookingNoticeData" />
    </template>
  </CoreDialog>
</template>

<script>
  import BookingNoticeForm from "../forms/BookingNoticeForm/BookingNoticeForm"
  import { BookingNotice } from "@/Modules/Models"

  export default {
    name: "BookingNoticeDialog",
    components: {
      BookingNoticeForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      bookingNoticeData() {
        const result = BookingNotice.find(this.id)

        // loop through time blocks and change the date format to a time "00:00" format if the date format is something like "2024-07-01T23:14:14.251Z" and consider day light savings if user is in Sydney, Australia
        if (result && result.timeBlocks) {
          result.timeBlocks.forEach(timeBlock => {
            if (timeBlock.startTime.includes('T')) {
              // consider daylight savings if user is in Sydney, Australia,
              // use 24-hour time format, no AM/PM
              const sydneyOptions = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'Australia/Sydney'
              };

              timeBlock.startTime = new Date(timeBlock.startTime).toLocaleTimeString('en-AU', sydneyOptions);
              timeBlock.endTime = new Date(timeBlock.endTime).toLocaleTimeString('en-AU', sydneyOptions);
            }
          })
        }
        return result
      },
      title() {
          return `Cloning Booking Notice #${this.id}`
      }
    },
    mounted() {
      if (this.id) {
        this.fetchBookingNotice()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchBookingNotice() {
        this.isLoading = true
        await BookingNotice.api().fetchById(this.id)
        this.isLoading = false
      }
    }
  }
</script>