<template>
  <div class="booking-overview">
    <el-card
        v-if="bookingNotice !== null"
        class="booking-card"
        shadow="never">
      <div
          slot="header"
          class="booking-header">
        <h3>Booking #{{ bookingNotice.id }}</h3>
        <span class="booking-date">{{ bookingNotice.date | formatDate }}</span>
      </div>

      <!-- Site Details Section -->
      <el-collapse v-model="activeCollapse">
        <el-collapse-item name="site">
          <template slot="title">
            <h4 class="collapse-title">
              <i class="el-icon-location-information" />
              <span>Site Details</span>
            </h4>
          </template>
          <el-card
              v-if="bookingSite"
              v-loading="bookingSite === null"
              class="site-details"
              shadow="never">
            <h4>{{ bookingSite.title }}</h4>
            <div class="site-info">
              <div class="info-item">
                <i class="el-icon-location" />
                <span>{{ bookingSite.street }}, {{ bookingSite.city }} {{ bookingSite.zip }} {{ bookingSite.state }}</span>
              </div>
              <div
                  v-if="bookingSite.contactEmail"
                  class="info-item">
                <i class="el-icon-message" />
                <span>{{ bookingSite.contactEmail }}</span>
              </div>
              <div
                  v-if="bookingSite.contactPhone"
                  class="info-item">
                <i class="el-icon-phone" />
                <span>{{ bookingSite.contactPhone }}</span>
              </div>
              <div
                  class="info-item">
                <i class="el-icon-link" />
                <a
                    :href="`https://bookings.essentialsm.com.au/booking-${id}`"
                    target="_blank">
                  Go to external Booking Page (Customers)
                </a>
                <el-button
                    class="copy-link-button"
                    :disabled="isCopying"
                    @click="copyLink(`https://bookings.essentialsm.com.au/booking-${id}`)">
                  <i :class="['copy-icon', isCopying ? 'el-icon-check' : 'el-icon-document-copy']" />
                  {{ isCopying ? 'Copied!' : 'Copy Link' }}
                </el-button>
              </div>
              <div
                  class="info-item">
                <i class="el-icon-link" />
                <a
                    :href="`https://bookings.essentialsm.com.au/summary-${id}`"
                    target="_blank">
                  Go to external Booking Summary (Staff)
                </a>
                <el-button
                    class="copy-link-button"
                    :disabled="isCopying"
                    @click="copyLink(`https://bookings.essentialsm.com.au/summary-${id}`)">
                  <i :class="['copy-icon', isCopying ? 'el-icon-check' : 'el-icon-document-copy']" />
                  {{ isCopying ? 'Copied!' : 'Copy Link' }}
                </el-button>
              </div>
              <div class="info-item">
                <i class="el-icon-document" />
                <span>Booking Notice Document: </span>
                <a
                    :href="documentDownloadUrl"
                    target="_blank"
                    class="document-link">
                  <i class="el-icon-download" />
                  Download
                </a>
                <el-button
                    class="copy-link-button"
                    :disabled="isCopying"
                    @click="copyLink(documentDownloadUrl)">
                  <i :class="['copy-icon', isCopying ? 'el-icon-check' : 'el-icon-document-copy']" />
                  {{ isCopying ? 'Copied!' : 'Copy Link' }}
                </el-button>
              </div>
            </div>
          </el-card>
        </el-collapse-item>
      </el-collapse>

      <!-- Time Blocks Section -->
      <div class="section-title">
        <i class="el-icon-time" />
        <h4>Booking Slots</h4>
        <el-button
            style="margin-left: 1rem"
            type="primary"
            size="mini"
            icon="el-icon-refresh"
            @click="getBookings()">
          Refresh
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-download"
            style="margin-left: 1rem"
            @click="handleExportBookingsBulk()">
          CSV Export All Bookings
        </el-button>
        <el-popconfirm
            style="margin-left: auto"
            title="Are you sure you want to clear all bookings?"
            confirm-button-text="Clear All"
            confirm-button-type="danger"
            @confirm="clearAllSlots">
          <el-button
              slot="reference"
              type="danger"
              size="mini"
              icon="el-icon-delete"
              :disabled="!hasActiveSlots">
            Clear all Slots
          </el-button>
        </el-popconfirm>
      </div>

      <el-table
          :data="bookingNotice.timeBlocks"
          stripe
          style="width: 100%"
          :row-class-name="tableRowClassName">
        <el-table-column
            label="#"
            width="50">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
            prop="startTime"
            label="Start Time"
            width="110">
          <template slot-scope="scope">
            {{ formatTime(scope.row.startTime) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="endTime"
            label="End Time"
            width="110">
          <template slot-scope="scope">
            {{ formatTime(scope.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="description"
            label="Description">
          <template slot-scope="scope">
            {{ scope.row.description | formatUnderscore }}
          </template>
        </el-table-column>
        <el-table-column
            label="Slots"
            width="120">
          <template slot-scope="scope">
            <el-tag
                :type="getSlotTagType(scope.row)"
                size="small">
              {{ scope.row.used }} / {{ scope.row.bookingSlots }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="Actions"
            width="200">
          <template slot-scope="scope">
            <div
                v-if="scope.row.used > 0"
                class="table-actions">
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-view"
                  @click="handleShowBookings(scope.row, scope.$index)">
                View
              </el-button>
              <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-download"
                  @click="handleExportBookings(scope.row, scope.$index)">
                Export
              </el-button>
            </div>
            <el-tag
                v-else
                type="info"
                size="small">
              No Bookings
            </el-tag>
          </template>
        </el-table-column>
      </el-table>

      <div v-loading="isLoadingBookings">
        <!-- Bookings List -->
        <div
            v-if="bookings"
            class="bookings-list">
          <el-divider content-position="left">
            Detailed Bookings
          </el-divider>

          <div
              v-if="selectedBlock"
              class="selected-slot-info">
            <span>{{ selectedBlock.block.description | formatUnderscore }}</span>
            <span class="slot-time">{{ selectedBlock.block.startTime | formatTime }} - {{ selectedBlock.block.endTime | formatTime }}</span>
          </div>

          <el-table
              v-loading="isLoadingBookings"
              :data="bookings"
              style="width: 100%; font-size: 1rem"
              stripe
              size="small">
            <el-table-column
                prop="name"
                label="Name"
                min-width="120" />
            <el-table-column
                prop="unitNumber"
                label="Unit"
                width="80" />
            <el-table-column
                prop="level"
                label="Level"
                width="80" />
            <el-table-column
                prop="phoneNumber"
                label="Phone"
                width="120" />
            <el-table-column
                prop="email"
                label="Email"
                min-width="180" />
            <el-table-column
                prop="comments"
                label="Comments"
                min-width="150">
              <template slot-scope="scope">
                <el-tooltip
                    v-if="scope.row.comments"
                    :content="scope.row.comments"
                    placement="top">
                  <div class="truncated-text">
                    {{ scope.row.comments }}
                  </div>
                </el-tooltip>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
                label="Last Email"
                width="150">
              <template slot-scope="scope">
                {{ scope.row.lastMailNotification | formatLastEmailDate }}
              </template>
            </el-table-column>
            <el-table-column
                label="Actions"
                width="120"
                fixed="right">
              <template slot-scope="scope">
                <el-tooltip
                    content="Resend Confirmation"
                    placement="top">
                  <el-button
                      type="text"
                      icon="el-icon-message"
                      @click="confirmResend(scope.row.id)" />
                </el-tooltip>
                <el-tooltip
                    content="Delete Booking"
                    placement="top">
                  <el-button
                      type="text"
                      icon="el-icon-delete"
                      class="danger-action"
                      @click="confirmDelete(scope.row.id)" />
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>

    <!-- Confirmation Dialogs -->
    <el-dialog
        title="Confirm Resend"
        :visible.sync="resendDialogVisible"
        width="30%"
        center>
      <span>Are you sure you want to resend the Booking Confirmation for this client?</span>
      <span
          slot="footer"
          class="dialog-footer">
        <el-button @click="resendDialogVisible = false">Cancel</el-button>
        <el-button
            type="primary"
            @click="handleResendConfirmation">Send Confirmation</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="Confirm Delete"
        :visible.sync="deleteDialogVisible"
        width="30%"
        center>
      <span>Are you sure you want to delete this Booking?</span>
      <span
          slot="footer"
          class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">Cancel</el-button>
        <el-button
            type="danger"
            @click="handleDeleteConfirmation">Delete Booking</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { BookingNotice, Site } from "@/Modules/Models";
import moment from "moment/moment";
import config from "@/config";

export default {
  name: "BookingsOverview",
  filters: {
    formatUnderscore(string) {
      if (!string) return '';
      return string.replace("_", " ");
    },
    formatTime(data) {
      return moment(data).format('h:mm A');
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatLastEmailDate(date) {
      if (date === '0000-00-00 00:00:00' || !date) {
        return "N/A";
      }
      return moment(date).format('DD/MM/YYYY h:mm A');
    }
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      bookingNotice: null,
      site: null,
      bookings: null,
      bookingIndex: null,
      selectedBlock: null,
      isLoadingBookings: false,
      activeCollapse: ['site'],
      resendDialogVisible: false,
      deleteDialogVisible: false,
      pendingBookingId: null,
      isCopying: false
    };
  },
  computed: {
    bookingSite() {
      return this.bookingNotice ? Site.find(this.bookingNotice.siteId) : null;
    },
    hasActiveSlots() {
      return this.bookingNotice && this.bookingNotice.timeBlocks
          ? this.bookingNotice.timeBlocks.some(block => block.used != 0)
          : false;
    },
    documentDownloadUrl() {
      let url = `${config.apiConfig.lightwerk.api}/notice-${this.id}`;
      url = url.replace('bookingsapi', 'downloads');
      url = url.replace('/api', '');
      return url
    }
  },
  async mounted() {
    await this.getBookings();
  },
  methods: {
    copyLink(url) {
      // Create a temporary input element
      const tempInput = document.createElement('input');
      tempInput.value = url;
      document.body.appendChild(tempInput);

      // Select and copy the URL
      tempInput.select();

      try {
        const successful = document.execCommand('copy');

        if (successful) {
          // Show success state
          this.isCopying = true;

          // Reset copied state after 2 seconds
          setTimeout(() => {
            this.isCopying = false;
          }, 2000);

          this.showNotification('success', 'Link Copied', 'Link copied to clipboard!', 2000);
        } else {
          this.showNotification('error', 'Copy Failed', 'Failed to copy link. Please try again.', 3500);
        }
      } catch (err) {
        this.showNotification('error', 'Copy Failed', 'Failed to copy link. Please try again.', 3500);
        console.error('Copy error:', err);
      }

      // Remove the temporary input element
      document.body.removeChild(tempInput);
    },
    formatTime(timeInput) {
      /**
       * Formats time input to "hh:mm AM/PM" format
       * Handles two input formats:
       * 1. ISO date string: "2025-03-03T22:00:00.000Z"
       * 2. 24-hour time string: "14:00"
       *
       * @param {string} timeInput - Time in either ISO format or 24-hour format
       * @returns {string} Time formatted as "hh:mm AM/PM"
       */

      let hours, minutes;

      // Check if input is ISO format or simple time format
      if (timeInput.includes('T')) {
        // ISO format: "2025-03-03T22:00:00.000Z"
        const date = new Date(timeInput);
        hours = date.getHours();
        minutes = date.getMinutes();
      } else {
        // Simple time format: "14:00"
        const parts = timeInput.split(':');
        hours = parseInt(parts[0], 10);
        minutes = parseInt(parts[1], 10);
      }

      // Convert to 12-hour format
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert "0" to "12"

      // Add leading zeros if needed
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes} ${period}`;
    },
    async getBookings() {
      this.isLoadingBookings = true;
      try {
        await BookingNotice.api().fetchById(this.id);
        this.bookingNotice = BookingNotice.find(this.id);
        await Site.api().fetchById(this.bookingNotice.siteId);
      } catch (error) {
        this.showNotification('error', 'Error', 'Unable to fetch booking details');
        console.error(error);
      }
      this.isLoadingBookings = false;
    },

    // eslint-disable-next-line no-unused-vars
    tableRowClassName({row, rowIndex}) {
      if (this.bookingIndex === rowIndex) {
        return 'selected-row';
      }
      return '';
    },

    getSlotTagType(block) {
      const ratio = block.used / block.bookingSlots;
      if (ratio === 0) return 'info';
      if (ratio < 0.5) return 'success';
      if (ratio < 0.8) return 'warning';
      return 'danger';
    },

    confirmResend(bookingId) {
      this.pendingBookingId = bookingId;
      this.resendDialogVisible = true;
    },

    confirmDelete(bookingId) {
      this.pendingBookingId = bookingId;
      this.deleteDialogVisible = true;
    },

    async handleResendConfirmation() {
      await this.resendBookingConfirmation(this.pendingBookingId);
      this.resendDialogVisible = false;
      this.pendingBookingId = null;
    },

    async handleDeleteConfirmation() {
      await this.deleteBooking(this.pendingBookingId);
      this.deleteDialogVisible = false;
      this.pendingBookingId = null;
    },

    async deleteBooking(bookingId) {
      this.isLoadingBookings = true;
      try {
        const deleteBookingResult = await BookingNotice.api().deleteBooking(bookingId);

        if (deleteBookingResult.response.status === 200) {
          this.showNotification('success', 'Thank You', 'The booking has been successfully cancelled.');
        }

        await BookingNotice.api().fetchById(this.id);
        this.bookingNotice = BookingNotice.find(this.id);

        if (this.selectedBlock) {
          const { block, index } = this.selectedBlock;
          await this.handleShowBookings(block, index);
        }
      } catch (error) {
        this.showNotification('error', 'Error', 'Failed to delete booking');
        console.error(error);
      } finally {
        this.isLoadingBookings = false;
      }
    },

    async resendBookingConfirmation(bookingId) {
      this.isLoadingBookings = true;
      try {
        const result = await BookingNotice.api().resendBookingConfirmation(bookingId);

        if (result.response.status === 200) {
          this.showNotification('success', 'Thank You', 'The booking confirmation email has been successfully sent.');
        }

        await BookingNotice.api().fetchById(this.id);
        this.bookingNotice = BookingNotice.find(this.id);

        if (this.selectedBlock) {
          const { block, index } = this.selectedBlock;
          await this.handleShowBookings(block, index);
        }
      } catch (error) {
        this.showNotification('error', 'Error', 'Failed to resend confirmation email');
        console.error(error);
      } finally {
        this.isLoadingBookings = false;
      }
    },

    async handleShowBookings(block, index) {
      this.isLoadingBookings = true;
      try {
        const payload = {
          timeStart: block.startTime,
          timeEnd: block.endTime
        };

        if (this.id > 122) {
          payload.timeBlockIndex = index
        }

        const result = await BookingNotice.api().getBookings(this.id, payload);

        this.bookings = result.response.data.data.data;
        this.bookingIndex = index;
        this.selectedBlock = { block, index };
      } catch (error) {
        this.showNotification('error', 'Error', 'Failed to fetch bookings');
        console.error(error);
      } finally {
        this.isLoadingBookings = false;
      }
    },
    async clearAllSlots() {
      await BookingNotice.api().clearAllBookings(this.id);
      await this.getBookings();
    },

    async handleExportBookings(block, index) {
      try {
        const response = await BookingNotice.api().exportBookings(
            this.id,
            {
              timeOffset: moment().utcOffset(),
              siteId: this.bookingSite.id,
              timeStart: block.startTime,
              timeEnd: block.endTime,
              timeBlockIndex: index
            }
        );

        const result = response.response.data;
        const fileLocation = result.fileLocation;

        if (fileLocation) {
          this.showNotification(
              'download',
              'Thank you',
              `Your Bookings CSV has been created. Downloaded here: <a href="${fileLocation}" target="_blank">Bookings Export #${this.id}</a>`,
              0
          );
        } else {
          this.showNotification(
              'error',
              'Error creating Bookings Export',
              'Sorry, your Booking Export could not be created.'
          );
        }
      } catch (error) {
        this.showNotification('error', 'Export Error', 'Failed to export bookings');
        console.error(error);
      }
    },
    async handleExportBookingsBulk() {
      try {
        const response = await BookingNotice.api().exportBookingsBulk(
            this.id,
            {
              siteId: this.bookingSite.id,
            }
        );

        const result = response.response.data;
        const fileLocation = result.fileLocation;

        if (fileLocation) {
          this.showNotification(
              'download',
              'Thank you',
              `Your Bookings CSV has been created. Downloaded here: <a href="${fileLocation}" target="_blank">Bookings Export #${this.id}</a>`,
              0
          );
        } else {
          this.showNotification(
              'error',
              'Error creating Bookings Export',
              'Sorry, your Booking Export could not be created.'
          );
        }
      } catch (error) {
        this.showNotification('error', 'Export Error', 'Failed to export bookings');
        console.error(error);
      }
    },

    showNotification(type, title, message, decay = 3500) {
      const notificationsPayload = {
        component: "Toast",
        type,
        title,
        message,
        visible: true,
        decay,
        position: "top-right"
      };
      this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload);
    }
  }
};
</script>

<style lang="scss" scoped>
.booking-overview {
  padding: 0;
  height: 100%;
  overflow: auto;
  font-size: 1rem;

  .booking-card {
    margin-bottom: 0;
    border-radius: 4px;

    .booking-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin: 0;
        font-size: 18px;
      }

      .booking-date {
        font-weight: bold;
        color: #606266;
      }
    }
  }

  .section-title {
    display: flex;
    align-items: center;
    margin: 16px 0;

    i {
      margin-right: 8px;
      color: #409EFF;
    }

    h4 {
      margin: 0;
      font-size: 16px;
    }
  }

  .collapse-title {
    display: flex;
    align-items: center;
    font-size: 16px;

    i {
      margin-right: 8px;
      color: #409EFF;
    }
  }

  .site-details {
    background-color: #f9fafc;

    h4 {
      margin-top: 0;
      margin-bottom: 12px;
      color: #303133;
    }

    .site-info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .info-item {
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
          color: #606266;
          width: 16px;
        }

        .document-link {
          margin: 0 8px;
        }

        .copy-link-button {
          display: inline-flex;
          align-items: center;
          padding: 0.25rem 0.5rem;
          height: auto;
          margin-left: 1rem;
          font-size: 13px;

          .copy-icon {
            margin-right: 4px;
          }

          &:hover {
            color: #409EFF;
          }
        }
      }
    }
  }

  .table-actions {
    display: flex;
    gap: 8px;
  }

  .bookings-list {
    margin-top: 20px;

    .selected-slot-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 8px 12px;
      background-color: #ecf5ff;
      border-radius: 4px;
      font-size: 14px;

      .slot-time {
        font-weight: bold;
      }
    }
  }

  .truncated-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .danger-action {
    color: #F56C6C;

    &:hover {
      color: #f78989;
    }
  }

  ::v-deep .selected-row {
    background-color: #ecf5ff !important;
  }

  ::v-deep .el-table {
    margin-bottom: 16px;
  }

  ::v-deep .el-divider__text {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>