const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Product",
    options: [
      {
        label: "ID, Name, Subtitle or SKU",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.product",
        inputType: "text",
        props: {
          placeholder: "Filter by Product Name, Subtitle or SKU"
        }
      },
      {
        label: "Categories",
        queryType: "search",
        urlKey: "filter.categories",
        inputType: "entity-select",
        props: {
          entity: "ShopCategory"
        }
      },
    ]
  },
  {
    label: "Product Dates",
    options: [
      {
        label: "Date Created",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.dateCreated",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Creation Date Time Frame"
        }
      },
      {
        label: "Date Updated",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.dateUpdated",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Update Date Time Frame"
        }
      },
    ]
  },
  {
    label: "Product Specifics",
    options: [
      {
        label: "Product Stock",
        inputType: "number",
        queryType: "search",
        urlKey: "filter.stock",
        props: {
          placeholder: "Filter by Stock"
        }
      },
      {
        label: "Product Price",
        inputType: "number",
        queryType: "search",
        urlKey: "filter.price",
        props: {
          placeholder: "Filter by Price"
        }
      },
      {
        label: "Pre-Order Deposit Price",
        inputType: "number",
        queryType: "search",
        urlKey: "filter.preOrderDepositPrice",
        props: {
          placeholder: "Filter by Pre-Order Deposit Price"
        }
      }
    ]
  },
]