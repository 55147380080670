<template>
  <div class="dashboard-base">
    <component :is="appName" />
  </div>
</template>

<script>
import TheStylex from "@/Modules/Dashboard/views/TheStylex"
import Otocare from "@/Modules/Dashboard/views/Otocare"
import CaneToadEquip from "@/Modules/Dashboard/views/CaneToadEquip"
import FireManagementServices from "@/Modules/Dashboard/views/FireManagementServices"

export default {
  name: "DashboardBase",
  components: {
    TheStylex,
    Otocare,
    CaneToadEquip,
    FireManagementServices
  },
  data() {
    return {
      appName: process.env.VUE_APP_BASE_PATH
    }
  }
}
</script>