import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import { startCase } from 'lodash'
import moment from "moment/moment";
import config from "@/config";

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/bookingNotices"

class BookingNotice extends ExtendedModel {
  static entity = "BookingNotice"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: { ...apiActions,
      async clearAllBookings(bookingNoticeId) {
      const url = `${baseURL}${endpoint}/clearAllBookings/${bookingNoticeId}`
        return this.get(url, null)
      },
      async lockBookingNotice(bookingNoticeId) {
        const url = `${baseURL}${endpoint}/lock/${bookingNoticeId}`
        return this.get(url, null)
      },
      async unlockBookingNotice(bookingNoticeId) {
        const url = `${baseURL}${endpoint}/unlock/${bookingNoticeId}`
        return this.get(url, null)
      }
    }
  }

  get Actions() {
    return new Actions(this)
  }

  get NoticeType() {
    return startCase(this.noticeType)
  }

  get NoticeDate() {
    return moment(this.date).format("DD/MM/YYYY")
  }
  get ExpiryDate() {
    return moment(this.bookingInactiveDate).format("DD/MM/YYYY")
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

}
export default BookingNotice
