// import Modules here and add them to the routes array to see them in the Side bar menu and screen
import Auth from "@/Modules/Core/Modules/Auth"
import Dashboard from "@/Modules/Dashboard"
import Main from "./views/Main"
import config from "./config"
import modules from "./modules"
import store from "@/Modules/Core/store"

const routes = [
  {
    beforeEnter: (to, from, next) => {
      const { loggedIn } = store.state.auth
      const { href, hash } = window.location

      if (!hash.includes("Login") && !href.includes("code") && !href.includes("state")) {
        localStorage.setItem("fromUrl", hash)
      }

      if (loggedIn) {
        next()
      } else {
        next({ name: "Login" })
      }
    },
    registerChildren: true,
    component: Main,
    path: "",
    enabled: false,
    main: true,
    meta: {
      requiresAuth: true
    },
    children: [{ ...Dashboard, path: "" }, Dashboard, ...modules],
    name: config.appConfig.appName
  },
  Auth.odyssey
]

export default routes
