import BookingNoticeTableView from "./views/BookingNoticeTableView"

const routes = [
  {
    path: "",
    name: "booking-noticer-list-view",
    component: BookingNoticeTableView
  },
]

export default routes